<template>
  <PageCard
    pageName="FINTECH Strategy"
    :iconClass="['cuis-map', 'card-icon-font']"
  >
    <template slot="page_content">
      <b-row class="about-row">
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">FI Segmentation</div>
            <b-card-body class="p-0">
              <div>
                Client segmentation can allow greater efficiency and
                customization of your sales and account management models. Your
                FI segmentation model can integrate traditional firmographics
                with purchase behavior (technographics) and performance
                characteristics (performographics).
              </div>
              <div class="my-2">
                Leveraging an effective segmentation model can translate into
                significant higher conversion and renewal rates – with less
                expenditure. The larger your client base the more important it
                is to create resource allocation efficiency through client
                segmentation.
              </div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <a
                    href="mailto:info@fi-navigator.com"
                    class="btn btn-secondary"
                    >More Info</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">White Space Assessment</div>
            <b-card-body class="p-0">
              <div>
                White space is opportunity. The opportunity could be to add an
                adjacent product to our current portfolio or to create a
                solution that heretofore didn’t exist. The starting point for
                white space assessment is a solution hierarchy.
              </div>
              <div class="my-2">
                Our coverage depth of all U.S. financial institutions coupled
                with the breadth our solution hierarchy positions FI Navigator
                to assist FinTech vendors in identifying unique white space
                opportunities. Leverage our data to skillfully plan your product
                portfolio expansion.
              </div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <a
                    href="mailto:info@fi-navigator.com"
                    class="btn btn-secondary"
                    >More Info</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">Other Strategic Applications</div>
            <b-card-body class="p-0">
              <div>
                FinTech organizations developing strategy in areas ranging from
                M&A, product development, account planning or investor
                communication can benefit from our data breadth and depth. Let
                us know about your project.
              </div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <a
                    href="mailto:info@fi-navigator.com"
                    class="btn btn-secondary"
                    >More Info</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'

export default {
  name: 'AboutStrategy',
  components: {
    PageCard
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'Fintech Strategy'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  }
}
</script>

<style lang="scss" scoped></style>
